import { Component } from '@angular/core';
import { IApplicationFilters, IFilterOption } from '../../shared.definitions';
import { CollectionFilterService } from '../../services/collection-filter.service';

@Component({
  selector: 'app-collection-filters',
  templateUrl: './collection-filters.component.html',
  styleUrls: ['./collection-filters.component.scss'],
})
export class CollectionFiltersComponent {
  activeFilters: IApplicationFilters;

  constructor(readonly filterService: CollectionFilterService) {}

  szepCardServicesFilterActive(filters: IFilterOption[]): any {
    return filters.findIndex((f) => f.checked) > -1;
  }

  serviceTypesFilterActive(filters: IFilterOption[]): any {
    return filters.findIndex((f) => f.checked) > -1;
  }

  destinationFilterActive(filters: IFilterOption[]): any {
    return filters.findIndex((f) => f.checked) > -1;
  }

  onClearFilter(filterType: keyof IApplicationFilters): void {
    this.filterService.resetFilter(filterType);
  }

  onOptionClick(option: IFilterOption): void {
    option.checked = !option.checked;

    this.filterService.updateFilters(JSON.parse(JSON.stringify(this.filterService.getFilters())));
  }
}
