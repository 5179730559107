<div class="card-popup">
  <div class="card-content">
    <div class="card-left">
      <h2>Most 20% kedvezménnyel foglalhatsz SZÉP-kártyával januárra és februárra!</h2>
      <p>A kedvezményes szálláshelyeket itt találod:</p>
      <button class="btn">MEGNÉZEM</button>
    </div>
    <div class="card-right">
      <img src="assets/images/szep-card-campaign/sauna_women.png" alt="Two women in a sauna" />
    </div>
  </div>
</div>
