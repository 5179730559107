import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../base.component';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CollectionMapService } from '../../services/collection-map.service';
import { Observable } from 'rxjs';
import { CollectionFilterService } from '../../services/collection-filter.service';

@Component({
  selector: 'app-application-collection-collection',
  templateUrl: './application-collection.component.html',
  styleUrls: ['./application-collection.component.scss'],
})
export class ApplicationCollectionComponent extends BaseComponent implements OnInit {
  subtitle = 'listing page subtitle';
  applications: any[];
  applicationLength: number;
  applicationMaxLength: number;
  applicationsPerPage = 1000;
  limitMultiplier = 1;
  type: string;
  uuid: string;
  searchControl: FormControl;
  title: string;
  isMobile = false;
  showFilters = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly translateService: TranslateService,
    readonly filterService: CollectionFilterService,
    private readonly collectioMapService: CollectionMapService
  ) {
    super();
  }

  ngOnInit(): void {
    const pageTypes = [
      { key: 'contentPageOnlyCampaign', type: 'campaign' },
      { key: 'contentPageOnlyRestaurant', type: 'restaurant' },
      { key: 'contentPageOnlySummerCampaign', type: 'summer-accomodation' },
      { key: 'contentPageOnlyEverySeasonCampaign', type: 'every-season' },
    ];

    for (const pageType of pageTypes) {
      const content = this.componentObject?.[pageType.key];
      if (content?.uuid) {
        this.type = pageType.type;
        this.uuid = content.uuid;
        this.title = content.title;
        this.defaultRequest();
        break;
      }
    }

    this.checkScreenSize();

    this.filterService.filters$.subscribe(() => {
      this.filterRequest(false);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  get isMapOpen$(): Observable<boolean> {
    return this.collectioMapService.isMapOpen$;
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth <= 768;

    if (!this.isMobile) {
      this.showFilters = true;
    } else {
      this.showFilters = false;
    }
  }

  defaultRequest(): void {
    const filters = {
      search: '',
      destinations: [],
      settlements: [],
      serviceTypes: [],
      summerServices: [],
      szepCardServices: [],
      accomondationTypes: [],
    };

    if (this.type === 'summer-accomodation') {
      filters.summerServices = [
        { id: 'typeAccommodation', text: this.translateService.instant(`CAMPAIGN.type.accommodation`), checked: false },
        { id: 'typeCateringUnit', text: this.translateService.instant(`CAMPAIGN.type.cateringunit`), checked: false },
        { id: 'typeAttraction', text: this.translateService.instant(`CAMPAIGN.type.attraction`), checked: false },
        { id: 'typeBathingPlace', text: this.translateService.instant(`CAMPAIGN.type.bathingplace`), checked: false },
      ];
    } else if (this.type === 'every-season') {
      filters.szepCardServices = [
        { id: 'typeAccommodation', text: 'Összes', checked: false },
        { id: 'typeCateringUnit', text: 'OTP SZÉP-kártya', checked: false },
        { id: 'typeAttraction', text: 'MBH SZÉP-kártya', checked: false },
        { id: 'typeBathingPlace', text: 'K&H SZÉP-kártya', checked: false },
      ];
    }

    this.searchControl = new FormControl(filters.search);

    this.applications = [];

    this.apiService.getApplications$(this.type, this.uuid, 0, this.applicationsPerPage * this.limitMultiplier).subscribe(
      (res) => {
        if (res?.meta?.filterOptions?.destinationTag_filter) {
          Object.keys(res.meta.filterOptions?.destinationTag_filter).forEach((key) => {
            filters.destinations.push({
              id: +key,
              text: this.translateService.instant(`CAMPAIGN.destination.${res.meta.filterOptions.destinationTag_filter[key]}`),
              checked: false,
            });
          });
        }
        if (res?.meta?.filterOptions?.serviceTypes_filter) {
          Object.keys(res.meta.filterOptions?.serviceTypes_filter).forEach((key) => {
            filters.serviceTypes.push({
              id: +key,
              text: res.meta.filterOptions.serviceTypes_filter[key],
              checked: false,
            });
          });
        }

        if (this.type === 'summer-accomodation') {
          filters.destinations.push({
            id: 0,
            text: this.translateService.instant('CAMPAIGN.destination.other'),
            checked: false,
          });
        }

        this.filterService.updateFilters(filters);

        // this.activeFilters = filters;
        this.applications = res.data;
        this.applicationMaxLength = res.meta.limit.allRowCount;
        this.applicationLength = this.applications.length;
        console.log(this.applications);
      },
      () => {
        // TODO: add error handling?
      }
    );
  }

  public onSearch(): void {
    this.filterService.updateFilter('search', this.searchControl.value);
  }

  filterRequest(isAddition: boolean): void {
    const activeFilters = this.filterService.getFilters();

    this.apiService
      .getApplications$(
        this.type,
        this.uuid,
        this.applicationsPerPage * (this.limitMultiplier - 1),
        this.applicationsPerPage * this.limitMultiplier,
        activeFilters.search,
        activeFilters.destinations,
        activeFilters.serviceTypes,
        activeFilters.summerServices,
        activeFilters.szepCardServices
      )
      .subscribe(
        (res) => {
          if (isAddition) {
            this.applications = [...this.applications, ...res.data];
          } else {
            this.applications = res.data;
          }
          this.applicationMaxLength = res.meta.limit.allRowCount;
          this.applicationLength = this.applications.length;
        },
        () => {
          // TODO: Add error handling?
        }
      );
  }

  loadMore(): void {
    this.limitMultiplier += 1;
    this.filterRequest(true);
  }

  resetLimitMultiplier(): void {
    this.limitMultiplier = 1;
  }

  toggleMap(): void {
    this.collectioMapService.toggleMap();
  }
}
