import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IApplicationFilters, IFilterOption } from '../shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class CollectionFilterService {
  private readonly defaultFilters: IApplicationFilters = {
    search: '',
    destinations: [],
    settlements: [],
    serviceTypes: [],
    summerServices: [],
    szepCardServices: [],
    accomondationTypes: [],
  };

  private readonly filtersSubject$ = new BehaviorSubject<IApplicationFilters>(this.defaultFilters);

  filters$ = this.filtersSubject$.asObservable(); // Observable for components to subscribe to

  getFilters(): IApplicationFilters {
    return this.filtersSubject$.value;
  }

  // Update the filters
  updateFilters(filters: IApplicationFilters): void {
    this.filtersSubject$.next(filters);
  }

  updateFilter<T>(key: keyof IApplicationFilters, value: T): void {
    // Get the current filters
    const currentFilters = this.getFilters();

    // Create a new filter object, updating only the specific key
    const updatedFilters: IApplicationFilters = {
      ...currentFilters,
      [key]: value,
    };

    // Emit the updated filters through the BehaviorSubject
    this.filtersSubject$.next(updatedFilters);
  }

  // Get the current filters
  getCurrentFilters(): IApplicationFilters {
    return this.filtersSubject$.value;
  }

  // Reset filters
  resetFilters(): void {
    this.filtersSubject$.next({
      search: '',
      destinations: [],
      settlements: [],
      serviceTypes: [],
      summerServices: [],
      szepCardServices: [],
      accomondationTypes: [],
    });
  }

  resetFilter(key: keyof IApplicationFilters): void {
    const currentFilters = this.getFilters();
    const updatedFilters: IApplicationFilters = {
      ...currentFilters,
      [key]: (currentFilters[key] as IFilterOption[]).map((filterOption: IFilterOption) => ({
        ...filterOption,
        checked: false,
      })),
    };

    this.filtersSubject$.next(updatedFilters);
  }
}
