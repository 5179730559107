import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { IAttractionFilter } from '../shared.definitions';
import { TrendencyUtilsService } from 'trendency/utils';
import { ApiService } from './api.service';

enum TypeIri {
  ATTRACTION = 'attraction',
  TOURINFORM_OFFICE = 'tourinform_office',
}

@Injectable({
  providedIn: 'root',
})
export class CollectionMapService implements OnDestroy {
  private readonly slugSubject$ = new BehaviorSubject<string>('');
  slug$ = this.slugSubject$.asObservable();

  private readonly searchSource$ = new BehaviorSubject<string>('');
  search$ = this.searchSource$.asObservable();

  private readonly isFilterOpenSource$ = new BehaviorSubject<boolean>(false);
  isFilterOpen$ = this.isFilterOpenSource$.asObservable();

  private readonly isMapOpenSource$ = new BehaviorSubject<boolean>(false);
  isMapOpen$ = this.isMapOpenSource$.asObservable();

  private readonly filtersSource$ = new BehaviorSubject<Array<IAttractionFilter>>([]);
  filters$ = this.filtersSource$.asObservable();

  private readonly activeFiltersSource$ = new BehaviorSubject<object>({});
  activeFilters$ = this.activeFiltersSource$.asObservable();

  private readonly isClearFilterActiveSource$ = new BehaviorSubject<boolean>(false);
  isClearFilterActive$ = this.isClearFilterActiveSource$.asObservable();

  private readonly querySource$ = new BehaviorSubject<any[]>([]);
  query$ = this.querySource$.asObservable();

  private readonly queryLengthSource$ = new BehaviorSubject<number>(0);
  queryLength$ = this.queryLengthSource$.asObservable();

  private readonly allItemsLengthSource$ = new BehaviorSubject<number>(0);
  allItemsLength$ = this.allItemsLengthSource$.asObservable();

  private readonly isLoaderExistSource$ = new BehaviorSubject<boolean>(false);
  isLoaderExist$ = this.isLoaderExistSource$.asObservable();

  private readonly isFinderLoadedSource$ = new BehaviorSubject<boolean>(false);
  isFinderLoaded$ = this.isFinderLoadedSource$.asObservable();

  private pageCounter = 1;
  private limit = 10;

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly trendencyUtilsService: TrendencyUtilsService,
    private readonly localizeRouterService: LocalizeRouterService
  ) {
    this.fetchUrlParams();
    this.fetchFiltersQuery();
    this.fetchAttractionSearchQuery();
    this.handleSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getLatLng(address: string): Promise<{ lat: number; lng: number }> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location;
          resolve({
            lat: location.lat(),
            lng: location.lng(),
          });
        } else {
          reject(`Geocoding failed: ${status}`);
        }
      });
    });
  }

  fetchUrlParams(): void {
    const params: Params = this.activatedRoute.snapshot.queryParams;

    if (params.search) {
      this.searchSource$.next(params.search);
    }

    if (params.filters) {
      this.activeFiltersSource$.next(JSON.parse(params.filters));
    }
  }

  setUrlParams(): void {
    const params: Params = {};
    const search: string = this.searchSource$.value;
    const activeFilters = this.activeFiltersSource$.value;

    if (search) {
      params.search = search;
    }

    if (activeFilters && Object.keys(activeFilters).length) {
      params.filters = JSON.stringify(activeFilters);
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
  }

  setSearch(val: string): void {
    this.searchSource$.next(val);
    this.setPageCounter(1);
    this.isClearFilterActiveFn();
  }

  toggleMobileFilter(): void {
    this.isFilterOpenSource$.next(!this.isFilterOpenSource$.value);
  }

  toggleMap(): void {
    this.isMapOpenSource$.next(!this.isMapOpenSource$.value);

    if (this.isMapOpenSource$.value) {
      this.document.body.style.overflow = 'hidden';
    } else {
      this.document.body.style.overflow = 'auto';
    }
  }

  setFilter(filters: IAttractionFilter[]): void {
    this.filtersSource$.next(filters);
    this.fetchAttractionSearchQuery();
  }

  setActiveFilter(filters: object): void {
    this.setPageCounter(1);
    this.activeFiltersSource$.next(filters);
    this.isClearFilterActiveFn();
  }

  isClearFilterActiveFn(): void {
    const isSearchEmpty = this.searchSource$.value.length !== 0;
    const isFilterEmpty = Object.entries(this.activeFiltersSource$.value).length !== 0;
    this.isClearFilterActiveSource$.next(isSearchEmpty || isFilterEmpty);
  }

  clearAllFilters(): void {
    this.activeFiltersSource$.next({});
    this.searchSource$.next('');
    this.setPageCounter(1);
    this.clearFilters();
    this.fetchAttractionSearchQuery();
    this.isClearFilterActiveFn();
  }

  private clearFilters(): void {
    this.filtersSource$.next(
      this.filtersSource$.value.map((filter) => {
        filter.data.map((item) => {
          item.value = false;
          if (item?.categories?.length) {
            item.categories.map((cat) => {
              cat.value = false;
              return item;
            });
          }
          return item;
        });
        return filter;
      })
    );
  }

  setisFinderLoaded(isLoaded: boolean): void {
    this.isFinderLoadedSource$.next(isLoaded);
  }

  setPageCounter(num?: number): void {
    this.pageCounter = num ? num : this.pageCounter + 1;
    this.fetchAttractionSearchQuery();
  }

  getAllItem(): void {
    this.setPageCounter(1);
    this.limit = this.allItemsLengthSource$.value;
    this.fetchAttractionSearchQuery();
  }

  private handleSearch(): void {
    this.slug$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((s: string) => this.apiService.getAttractionSearch$(s)),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res?.data) {
          if (this.pageCounter === 1) {
            // this.querySource.next(res.data);
            this.convertQuery(res.data);
            this.queryLengthSource$.next(res.data.length);
          }
          if (this.pageCounter > 1) {
            this.convertQuery([...this.querySource$.value, ...res.data]);
            // this.querySource.next([...this.querySource.value, ...res.data]);
            this.queryLengthSource$.next(this.querySource$.value.length);
          }
        }
        if (res?.info) {
          this.limit = res.info.limit;
          this.allItemsLengthSource$.next(res.info.sum);
          this.isLoaderExistSource$.next(this.queryLengthSource$.value < this.allItemsLengthSource$.value);
        }
      });
  }

  private convertQuery(res): void {
    this.querySource$.next(
      res.map((item) => {
        const typeIri = item?.typeiri;
        if (typeIri) {
          const cleanTypeIri = this.trendencyUtilsService.sliceStringAfterCharacter(typeIri, '#').toLowerCase();
          if (TypeIri.ATTRACTION === cleanTypeIri) {
            item.link = <string[]>this.localizeRouterService.translateRoute(['/', 'attraction-finder', item?.entity['mtu-neta:neta_id']]);
          }
          if (TypeIri.TOURINFORM_OFFICE === cleanTypeIri) {
            const address = item?.entity?.address?.settlement_name;
            const cleanAddress = this.trendencyUtilsService.generateSlug(address);
            item.link = <string[]>this.localizeRouterService.translateRoute(['/', 'tourinform', cleanAddress]);
          }
        }
        return item;
      })
    );
  }

  private fetchAttractionSearchQuery(): void {
    let slug = '';
    const search: string = this.searchSource$.value;
    const activeFilters = this.activeFiltersSource$.value;

    if (search) {
      slug += `/${search}`;
    }

    if (activeFilters && Object.keys(activeFilters).length) {
      slug += `?filters=${JSON.stringify(activeFilters)}`;
    }

    if (this.pageCounter > 1) {
      slug += !slug.includes('?') ? '?' : '&';
      slug += `page=${this.pageCounter}`;
    }

    if (this.limit === this.allItemsLengthSource$.value) {
      slug += !slug.includes('?') ? '?' : '&';
      slug += `offset_limit=${this.limit}`;
    }

    this.setUrlParams();
    this.slugSubject$.next(slug);
  }

  private fetchFiltersQuery(): void {
    this.apiService.getAttractionSearchFilter$().subscribe((res) => {
      if (res && res.tree && res.translated) {
        const tree = res.tree;
        const translation = res.translated;

        this.filtersSource$.next([
          this.convertDestinations(tree, translation),
          this.convertTypes(tree, translation),
          this.getTourinformAsCategory(tree, translation),
        ]);

        this.setFiltersByParams();
      }
    });
  }

  private setFiltersByParams(): void {
    const activeFilters = this.activeFiltersSource$.value;
    if (activeFilters) {
      Object.keys(activeFilters).map((type) => {
        const value = activeFilters[type];
        Object.keys(value).map((ctg) => {
          const filterKeys = value[ctg];
          for (const fk of filterKeys) {
            this.changeCheckbox(type, ctg, fk);
          }
        });
      });
    }
  }

  private convertTypes(tree, translation): IAttractionFilter {
    const mainTree = tree?.mainTree;
    const data = [];
    for (const key of Object.keys(mainTree)) {
      const value = mainTree[key];
      if (key !== 'tourinform_office') {
        data.push({
          name: key,
          label: translation?.subCategories[key] ?? key,
          value: false,
          icon: key,
          isSubCategoriesOpen: false,
          categories: value?.labels?.map((item) => {
            return {
              name: item,
              label: translation?.labels[item] ?? key,
              value: false,
            };
          }),
        });
      }
    }
    return {
      type: 'types',
      label: 'Type',
      isOpen: false,
      data: data,
    };
  }

  private getTourinformAsCategory(tree, translation): IAttractionFilter {
    const mainTree = tree?.mainTree;
    const data = [];
    for (const key of Object.keys(mainTree)) {
      if (key === 'tourinform_office') {
        data.push({
          name: key,
          label: translation?.subCategories[key] ?? key,
          value: false,
          icon: key,
          isSubCategoriesOpen: false,
        });
      }
    }

    return {
      type: 'tourinform',
      label: 'offices',
      isOpen: false,
      data: data,
    };
  }

  private convertDestinations(tree, translation): IAttractionFilter {
    return {
      type: 'destinations',
      label: 'Destination',
      isOpen: false,
      data: tree.tabDefaults.destinations.map((key) => {
        return {
          name: key,
          label: translation?.destinations[key] ?? key,
          value: false,
        };
      }),
    };
  }
  changeCheckbox(type: string, ctg: string, filterKeys: string | Array<object>): void {
    const fs: IAttractionFilter[] = [...this.filtersSource$.value];
    const activeFilterType = this.activeFiltersSource$.value['types'];
    const ctgValue = !!(activeFilterType && activeFilterType[ctg]);

    fs.map((f) => {
      if (f.type === type) {
        f.isOpen = true;
        f.data.map((cat) => {
          if (cat.categories && cat.name === ctg) {
            cat.isSubCategoriesOpen = true;
            cat.value = ctgValue;

            cat.categories.map((subCat) => {
              if (!Array.isArray(filterKeys)) {
                if (subCat?.name === filterKeys) {
                  subCat.value = !subCat.value;
                }
                return subCat;
              }

              return filterKeys.map((fk) => {
                if (subCat?.name === fk['name']) {
                  subCat.value = ctgValue;
                }
                return fk;
              });
            });
          }
          if (!cat.categories && cat.name === ctg) {
            cat.value = !cat.value;
          }
          return cat;
        });
      }
      return f;
    });

    this.filtersSource$.next(fs);
    this.fetchAttractionSearchQuery();
  }

  cleanFilter(type: string, ctg: string, activeFilters: object): object {
    const af: object = { ...activeFilters };
    if (!af[type][ctg].length) {
      delete af[type][ctg];
    }
    if (!Object.keys(af[type]).length) {
      delete af[type];
    }
    return af;
  }

  provideFilter(type: string, ctg: string, filterKey: string): void {
    const af: object = { ...this.activeFiltersSource$.value };

    if (!af[type] || !af[type][ctg]) {
      af[type] = { ...af[type], [ctg]: [filterKey] };
      this.setActiveFilter(this.cleanFilter(type, ctg, af));
      return;
    }

    if (af[type][ctg].includes(filterKey)) {
      af[type][ctg] = af[type][ctg].filter((key) => key !== filterKey);
    } else {
      af[type][ctg].push(filterKey);
    }

    this.setActiveFilter(this.cleanFilter(type, ctg, af));
  }

  provideMultipleFilters(type: string, ctg: string, filterKeys: object[]): void {
    const af: object = { ...this.activeFiltersSource$.value };

    if (!af[type] || !af[type][ctg]) {
      af[type] = { ...af[type], [ctg]: [] };
      filterKeys.forEach((fk) => {
        af[type][ctg].push(fk['name']);
      });
    } else {
      af[type][ctg] = [];
    }

    this.setActiveFilter(this.cleanFilter(type, ctg, af));
  }
}
