import { Injectable } from '@angular/core';
import { TrendencyReqService } from 'trendency/http';
import { TranslateService } from '@ngx-translate/core';
import { IFilterOption } from '../shared.definitions';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly reqService: TrendencyReqService, private readonly translate: TranslateService) {}

  public get lang(): string {
    return this.translate.currentLang;
  }

  public getInit$(): Observable<any> {
    return this.reqService.get(`init`);
  }

  public getContentList$(listType: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/${listType}`);
  }

  public getHomePage$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/HomePage`);
  }

  public getContentPreview$(previewHash: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content-preview/${previewHash}`);
  }

  public getContent$(pageType: string, slug: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/${pageType}/${slug}/view`);
  }

  public getDocumentsByGroup$(groupUuid: string, limit: number): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents-by-group/${groupUuid}?offset_limit=${limit}`);
  }

  public getDocumentsByDestination$(destinationTagUuid: string, limit: number): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents-by-destination/${destinationTagUuid}?offset_limit=${limit}`);
  }

  public getDocumentGroups$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/document-groups`);
  }

  public getMenuTreeIndex$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/menu/tree`);
  }

  public getMenuTreeList$(groupKey: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/menu/group/${groupKey}/recommendations`);
  }

  public getTrips$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    tripLengths?: IFilterOption[],
    targetAudiences?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/content/Trips?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (tripLengths) {
      tripLengths
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&tripLength_filter[]=${f.id}`;
        });
    }
    if (targetAudiences) {
      targetAudiences
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&targetAudienceTags_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  public getArticles$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    thematics?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/content/Articles?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (thematics) {
      thematics
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&thematicTags_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  public getEvents$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    thematics?: IFilterOption[],
    dateRange?: Date[],
    isArchive?: boolean
  ): Observable<any> {
    // let url = `/${this.lang}/event-search${term ? '/' + term : ''}?rowFrom_limit=${from}&rowTo_limit=${to}`;
    let url = `/${this.lang}/content/Events?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (thematics) {
      thematics
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&thematicTags_filter[]=${f.id}`;
        });
    }
    if (dateRange && dateRange.length > 0) {
      const gt = this.transform(dateRange[0]);
      const lt = this.transform(dateRange[1]);
      url += `&eventDateGt_filter=${gt}&eventDateLt_filter=${lt}`;
    }
    if (isArchive) {
      url += `&isArchive=1`;
    }

    return this.reqService.get(url);
  }

  public getTourinforms$(
    from?: number,
    to?: number,
    term?: string,
    destinations?: IFilterOption[],
    services?: IFilterOption[],
    languages?: IFilterOption[]
  ): Observable<any> {
    let url = `/hu/tourinforms?listingInfo`;
    if (from != null) {
      url += `&rowFrom_limit=${from}`;
    }
    if (to != null) {
      url += `&rowTo_limit=${to}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (services) {
      services
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&service_filter[]=${f.id}`;
        });
    }
    if (languages) {
      languages
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&language_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  public getApplications$(
    type: string,
    uuid: string,
    from: number,
    to: number,
    term?: string,
    destinations?: IFilterOption[],
    serviceType?: IFilterOption[],
    summerServices?: IFilterOption[],
    szepCardServices?: IFilterOption[]
  ): Observable<any> {
    const extraLang = type === 'summer-accomodation' ? '/hu' : '';
    let url = `/${this.lang}/${type}/${uuid}/applications${extraLang}?listingInfo&rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          let id = 'null';
          if (f.id !== 0) {
            id = String(f.id);
          }
          url += `&destinationTag_filter[]=${id}`;
        });
    }
    if (serviceType) {
      serviceType
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&serviceTypes_filter[]=${f.id}`;
        });
    }
    if (summerServices) {
      summerServices
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&${f.id}=true`;
        });
    }
    if (szepCardServices) {
      szepCardServices
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&${f.id}=true`;
        });
    }
    return this.reqService.get(url);
  }

  public getTourinform$(uuid): Observable<any> {
    return this.reqService.get(`/hu/tourinform/${uuid}?listingInfo`);
  }

  public getFilteredExperiences$(
    from: number,
    to: number,
    experienceTagId?: number,
    destinations?: IFilterOption[],
    experienceType?: IFilterOption[],
    influencer?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/content/Experiences?listingInfo&rowFrom_limit=${from}&rowTo_limit=${to}`;

    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTag_filter[]=${f.id}`;
        });
    }

    if (experienceType) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&experienceType_filter[]=${f.id}`;
        });
    }

    if (experienceTagId) {
      url += `&experienceTags_filter[]=${experienceTagId}`;
    }

    if (influencer) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&influencer_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  public getExperiences$(): Observable<any> {
    return this.reqService.get(`/hu/content/Experiences?listingInfo`);
  }

  public getExperience$(slug): Observable<any> {
    return this.reqService.get(`/hu/content/Experience/${slug}/view?listingInfo`);
  }

  public getExperienceByUuid$(uuid): Observable<any> {
    return this.reqService.get(`/hu/content/Experiences?uuid_filter=${uuid}`);
  }

  public getExperiencesByInfluencerId$(from: number, to: number, id): Observable<any> {
    return this.reqService.get(
      `/${this.lang}/content/Experiences?listingInfo&rowFrom_limit=${from}&rowTo_limit=${to}&influencer_filter[]=${id}`
    );
  }

  transform(value: any): any {
    if (value) {
      const format = 'YYYY-MM-DD';
      const timeZone = 'Europe/Budapest';
      return moment.utc(value).tz(timeZone).format(format);
    }
  }

  // - /publicapi/{_locale}/attraction-search/{slug}
  public getAttractionSearch$(slug): Observable<any> {
    let url = `/${this.lang}/attraction-search`;
    if (slug) {
      url += `${slug}`;
    }
    return this.reqService.get(url);
  }

  // - /publicapi/{_locale}/attraction-search-item/{attractionId}
  public getAttractionSearchItem$(attractionId): Observable<any> {
    return this.reqService.get(`/${this.lang}/attraction-search-item/${attractionId}`);
  }

  // - /publicapi/en/search-filter/
  public getAttractionSearchFilter$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/search-filter/`);
  }

  public getSearch$(slug: string): Observable<any> {
    let url = `/${this.lang}/search`;
    if (slug) {
      url += `/${slug}`;
    }
    return this.reqService.get(url);
  }

  // - /publicapi/{_locale}/search-item/{slug}
  public getSearchItem$(slug): Observable<any> {
    return this.reqService.get(`/${this.lang}/search-item/${slug}`);
  }

  public getFile$(uuid: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents/preview/${uuid}`);
  }

  getCampaignForm$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/campaign/application`);
  }

  postCampaignForm$(uuid: string, data: object): Observable<any> {
    return this.reqService.post(`/${this.lang}/campaign/application?campaignPageUuid=${uuid}`, data);
  }

  getRefugeeForm$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/accomodation/registration`);
  }

  postRefugeeForm$(uuid: string, data: object): Observable<any> {
    return this.reqService.post(`/${this.lang}/accomodation/registration?accomodationPageUuid=${uuid}`, data);
  }

  getSummerCampaignRegistrations$(uuid: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/summer-accomodation/${uuid}/applications/hu`);
  }

  getSummerCampaignForm$(uuid: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/summer-accomodation/registration?accomodationPageUuid=${uuid}`);
  }

  postSummerCampaignForm$(uuid: string, data: object): Observable<any> {
    return this.reqService.post(`/${this.lang}/summer-accomodation/registration?accomodationPageUuid=${uuid}`, data);
  }

  getRestaurantForm$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/restaurant/registration`);
  }

  postRestaurantForm$(uuid: string, data: object): Observable<any> {
    return this.reqService.post(`/${this.lang}/restaurant/registration?campaignPageUuid=${uuid}`, data);
  }

  getSearchDetails$(id: string): Observable<any> {
    return this.reqService.get(
      `/${this.lang}/get-item-search/?id=${encodeURIComponent(
        'http://mtu.gov.hu/api/api/622c96d147afdb5ebe0e72946b45d8a6/neta_attraction/' + id
      )}`
    );
  }
}
