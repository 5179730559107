<div class="attraction-map-container">
  <div class="modal"></div>
  <div class="content">
    <button class="button-close-map" (click)="toggleMap()">
      <i class="icon icon-close-red"></i>
    </button>

    <div class="left">
      <div class="map-search"></div>
      <div class="filters-column">
        <app-collection-filters></app-collection-filters>
      </div>
    </div>
    <div class="right" [ngClass]="{ 'mid-opened': isListOpen }">
      <app-full-width-map
        [markers]="markers$ | async"
        [fullHeight]="true"
        [centerLatLng]="centerTo"
        [useInCollection]="true"
      ></app-full-width-map>
    </div>
  </div>
</div>
