import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardComponent {
  @Input() card: any;
  @Input() isMapView = false;

  getBadgeUrl(cardName: string): string {
    switch (cardName) {
      case 'MBH SZÉP-kártya':
        return '/assets/images/szep-card-campaign/mbh_szep_card.png';
      case 'K&H SZÉP-kártya':
        return '/assets/images/szep-card-campaign/kh_szep_card.png';
      case 'OTP SZÉP-kártya':
        return '/assets/images/szep-card-campaign/otp_szep_card.png';
      default:
        return '';
    }
  }
}
