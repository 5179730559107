<ng-container *ngIf="filterService.filters$ | async as activeFilters">
  <ng-container *ngIf="activeFilters?.destinations?.length">
    <div class="filter-box-section">
      <div class="filter-box-title">{{ 'label.destinations' | translate }}</div>
      <button class="clear-all-filter" *ngIf="destinationFilterActive(activeFilters.destinations)" (click)="onClearFilter('destinations')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button>
      <ng-container *ngFor="let filterOption of activeFilters.destinations">
        <div class="option" (click)="onOptionClick(filterOption)">
          <div class="checker" [ngClass]="{ checked: filterOption.checked }">
            <div class="check"></div>
          </div>
          <span class="text">{{ filterOption.text }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="activeFilters?.serviceTypes?.length">
    <div class="filter-box-section">
      <div class="filter-box-title">{{ 'label.service-types' | translate }}</div>
      <button class="clear-all-filter" *ngIf="serviceTypesFilterActive(activeFilters.serviceTypes)" (click)="onClearFilter('serviceTypes')">
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button>
      <ng-container *ngFor="let filterOption of activeFilters.serviceTypes">
        <div class="option" (click)="onOptionClick(filterOption)">
          <div class="checker" [ngClass]="{ checked: filterOption.checked }">
            <div class="check"></div>
          </div>
          <span class="text">{{ filterOption.text }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="activeFilters?.szepCardServices?.length">
    <div class="filter-box-section">
      <div class="filter-box-title">SZÉP-KÁRTYA</div>
      <button
        class="clear-all-filter"
        *ngIf="szepCardServicesFilterActive(activeFilters.szepCardServices)"
        (click)="onClearFilter('szepCardServices')"
      >
        <i class="icon icon-close-red"></i>
        {{ 'label.clear-filter' | translate }}
      </button>
      <ng-container *ngFor="let filterOption of activeFilters.szepCardServices">
        <div class="option" (click)="onOptionClick(filterOption)">
          <div class="checker" [ngClass]="{ checked: filterOption.checked }">
            <div class="check"></div>
          </div>
          <span class="text">{{ filterOption.text }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
